/** @format */

import {
  GETCallHelper,
  POSTCallHelper,
  PUTCallHelper,
} from "../../../services";
import { handleError } from "../../../errorServices";

export default {
  async submitShiftActivity({ commit }, payload) {
    try {
      const response = await POSTCallHelper(
        "/ma/model-day-shifts/submit",
        payload
      );
      if (response.data.success) {
        commit("updateStatusOfShiftActivities", response.data.message.status);
        commit("updateShiftStatus", {
          shiftId: payload.shiftId,
          status: response.data.message.status,
        });
      }
    } catch (e) {
      handleError(e);
    }
  },

  async requestRedoOrExtension({ commit }, payload) {
    try {
      const response = await POSTCallHelper(
        "/ma/model-day-shifts/request",
        payload
      );
      if (response.data.success) {
        commit("updateStatusOfSubmittedShiftActivities", payload.status);
      }
    } catch (e) {
      handleError(e);
    }
  },

  async fetchShiftActivities({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `ma/model-day-shifts/shift-activities?practice=${payload.practiceId}&shift=${payload.shift}`
      );
      commit("setShiftActivities", response.data["instance-activities"]);
    } catch (e) {
      handleError(e);
    }
  },
  async fetchSingleInstance({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `/ma/model-day-shifts/single-instance?instance=${payload.instance}&shift=${payload.shift}`
      );
      commit("setInstance", response.data.instance);
    } catch (e) {
      handleError(e);
    }
  },

  async fetchStatusesOfAllShifts({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `/ma/model-day-shifts/statuses?practice=${payload.practiceId}`
      );
      commit("setAllShiftStatuses", response.data["shift-statuses"]);
    } catch (e) {
      handleError(e);
    }
  },

  async updateInstanceSubmission({ commit }, payload) {
    try {
      const response = await PUTCallHelper(
        `/ma/model-day-shifts/update?instance=${payload.instance}&practice=${payload.practice}`,
        payload
      );
      if (response.data.success) {
        commit("updateStatusOfShiftActivities", response.data.message.status);
        commit("updateShiftStatus", {
          shiftId: payload.shiftId,
          status: response.data.message.status,
        });
      }
    } catch (e) {
      handleError(e);
    }
  },

  /**
   * ----------------------
   * MA Dash ActiveShift
   * ----------------------
   */

  async fetchActiveShift({ commit }, payload) {
    try {
      const response = await GETCallHelper(
        `/ma/model-day-shifts/dashboard-count?practice=${payload.practice}`
      );
      commit("setActiveShift", response.data);
    } catch (e) {
      handleError(e.response.data);
    }
  },
};
