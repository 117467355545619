/** @format */

import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

export default {
  namespaced: true,
  state() {
    return {
      activities: [],
      instanceRequests: {},
      instanceSubmissions: {},
      instanceCount: [],
      singleInstance: {},
      thresholds: {},
      dashInstanceCount: {},
    };
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
